import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, snapshotActions } from "ducks/slices/snapshotSlice";
import i18n, { languageKeys } from "../../../../../i18n";
import _ from "lodash";
import { doFetchBook, doFetchPaymentMethod, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { Form } from "antd";
import { calcTotal } from "helpers";


const useDvChTT = ({ form, selectedService, setDisabledCongNo, dataSource, Payment, currentPatient }) => {

    const { paymentMethod: dsHinhThucThanhToan, invoiceBook: dsSoHoaDon } = useSelector(getSharedData);

    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.auth.user)

    // Khởi tạo danh sách sổ hóa đơn
    useEffect(() => {
        !dsSoHoaDon.length && dispatch(doFetchBook({ LOAI_SO: 'SO_THU_TIEN' }))
        !dsHinhThucThanhToan.length && dispatch(doFetchPaymentMethod())
        return () => {
            dispatch(setLoading(false));
            dispatch(snapshotActions.setDisabled(false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //
    useEffect(() => {
        dsSoHoaDon?.length > 0 && form.setFields([
            {
                name: "SO_HOADON_ID",
                value: dsSoHoaDon?.[0]?.ID,
                errors: [],
            },
        ]);
    }, [dsSoHoaDon])

    //
    useEffect(() => {
        if (!dataSource?.length) {
            dispatch(snapshotActions.setDisabled(true));
        } else {
            dispatch(snapshotActions.setDisabled(false));
        }
    }, [dataSource])

    //
    const formatDataSource = (data = [], isBundle) => {
        const packetServiceList = data.filter(item => item.IS_GOI_DV === 1)
        const restServiceList = data.filter(item => item.IS_GOI_DV !== 1)

        // Thanh toán mua gói
        if (isBundle) {
            let list = []
            const format = data.map(item => ({
                ...item,
                UNIQ_BUNDLE_ID: item.key,
                title: item.TEN_GOI_DV,
                children: item.DS_DV?.map(i => ({
                    ...i,
                    TEN_DICHVU: i.TEN_DV,
                    DON_GIA: i.DON_GIA_PHAN_BO,
                    MIEN_GIAM_DICH_VU: item.MIEN_GIAM_DICH_VU
                        ? item.GIAM_PHAN_TRAM > 0
                            ? Math.round(i.MIEN_GIAM + ((i.THANH_TIEN_PHAN_BO - i.MIEN_GIAM) / 100 * item.GIAM_PHAN_TRAM))
                            : Math.round((item.MIEN_GIAM_DICH_VU + calcTotal(item.DS_DV, "MIEN_GIAM")) * parseFloat(i.TY_LE) / 100)
                        : i.MIEN_GIAM,
                    key: `${i.ID}${item.key}`
                }))
            }))
            format.forEach(item => {
                list.push(item, {
                    data: item,
                    key: item.ID,
                    UNIQ_BUNDLE_ID: true,
                    SUMMARY: true,
                    TONG_TIEN: item.TONG_TIEN_PHAN_BO,
                    TONG_TIEN_GIAM: item.TONG_TIEN_GIAM,
                    THANH_TIEN: item.TONG_TIEN_PHAN_BO - calcTotal(item.children, "MIEN_GIAM_DICH_VU"),
                })
            })
            return list;
        }

        // Thanh toán dịch vụ
        let bundleArr = []
        if (packetServiceList?.length > 0) {
            const arrBundleID = _.uniq(packetServiceList.flatMap(i => i.MA_GOI_DV))
            arrBundleID.forEach((item, index) => {
                const arr = packetServiceList.filter(i => i.MA_GOI_DV === item)
                if (arr) {
                    bundleArr.push(
                        {
                            key: `${arr[0]?.TEN_GOI_DV}${arr[0]?.TEN_LOAI_GOI}${index}`,
                            BUNDLE_INFO: true,
                            GOI_DV: arr[0]?.TEN_GOI_DV,
                            LOAI_GOI_DV: arr[0]?.TEN_LOAI_GOI
                        },
                        ...arr
                    )
                }
            })
        }
        if (packetServiceList?.length === 0) {
            return [
                {
                    key: 'KHAM_DICH_VU',
                    title: i18n.t(languageKeys.kham_dich_vu),
                    children: restServiceList?.map(item => ({ ...item }))
                }
            ]
        }
        if (restServiceList?.length === 0) {
            return [
                {
                    key: 'KHAM_GOI_DICH_VU',
                    title: i18n.t(languageKeys.kham_goi_dich_vu),
                    children: bundleArr
                }
            ]
        }
        return [
            {
                key: 'KHAM_DICH_VU',
                title: i18n.t(languageKeys.kham_dich_vu),
                children: restServiceList?.map(item => ({ ...item }))
            },
            {
                key: 'KHAM_GOI_DICH_VU',
                title: i18n.t(languageKeys.kham_goi_dich_vu),
                children: bundleArr
            }
        ]
    }

    //
    const MIEN_GIAM = Form.useWatch("MIEN_GIAM", form)
    //
    const tong = selectedService?.reduce((acc, curr) => {
        if (currentPatient?.LOAI === "THANH_TOAN_MUA_GOI") {
            return acc + Number(curr.TONG_TIEN_PHAN_BO || 0) - calcTotal(curr.children, "MIEN_GIAM_DICH_VU");
        } else {
            return acc + Number(curr.SO_LUONG || 0) * Number(curr.DON_GIA || 0) - Number(curr.GIAM_TIEN || 0);
        }
    }, 0);
    //
    const tienMG = MIEN_GIAM
        ? MIEN_GIAM > 100 ? MIEN_GIAM : (tong / 100 * MIEN_GIAM)
        : 0
    //
    const tongChiPhi = selectedService?.reduce((acc, curr) => {
        return acc + (curr.DON_GIA || 0) * (curr.SO_LUONG || 0);
    }, 0)
    //
    const tongMienGiam = (
        currentPatient?.LOAI === "THANH_TOAN_MUA_GOI"
            ? formatDataSource(selectedService, true).filter(i => !i.SUMMARY).reduce((acc, curr) => acc + calcTotal(curr.children, "MIEN_GIAM_DICH_VU"), 0)
            : calcTotal(selectedService, "GIAM_TIEN")
    ) + tienMG
    //
    const tongTienCanThanhToan = () => {
        let tienTT
        currentPatient?.LOAI === "THANH_TOAN_MUA_GOI"
            ? tienTT = calcTotal(formatDataSource(selectedService, true).filter(i => i.SUMMARY), "THANH_TIEN")
            : tienTT = tong - tienMG
        setDisabledCongNo(tienTT === 0)
        return tienTT
    };

    //
    const checkDuplicateMethod = (_, value) => {
        const CheckMethod = form.getFieldValue("list_dv_thanh_toan")
            .filter(i => i?.HINH_THUC_THANH_TOAN === value).length > 1
        if (CheckMethod) {
            return Promise.reject("HTTT bị trùng");
        }
        return Promise.resolve();
    };

    //
    const handleSelectPaymentMethod = (val, name) => {
        let chosenMethod = dsHinhThucThanhToan.find(i => i.ID === val)?.TEN_HINH_THUC_THANH_TOAN
        form.setFieldValue(["list_dv_thanh_toan", name, "TEN_HINH_THUC_THANH_TOAN"], chosenMethod)
        if (['tiền mặt', 'cash'].includes(chosenMethod.toLowerCase())) {
            if (Payment.length === 1) {
                form.setFieldValue("TIEN_KHACH_DUA", tongTienCanThanhToan())
            } else {
                form.setFieldValue("TIEN_KHACH_DUA", form.getFieldValue(["list_dv_thanh_toan", name, "BENH_NHAN_DUA"]))
            }
        }
    }

    //
    const formatTempDataPrint = (dataSource = [{}]) => {
        //
        const { benh_vien, config } = userProfile
        const data = form.getFieldValue(undefined)
        //
        const commonInfo = {
            hospital: {
                avatar: benh_vien.ANH_DAI_DIEN,
                name: benh_vien.TEN_CSKCB,
                phone: benh_vien.SO_DIEN_THOAI,
                address: benh_vien.DIA_CHI_CHI_TIET
            },
            benh_nhan: {
                ma_benh_nhan: currentPatient.MA_BENH_NHAN,
                gioi_tinh: currentPatient.GIOI_TINH,
                // "sdt": "0915120040",
                ten_benh_nhan: currentPatient.TEN,
                // "dia_chi": "38 lê văn thiêm, Phường Long Châu, Thị xã Tân Châu, Tỉnh An Giang",
                // "tuoi": "31 tuổi",
                ngay_sinh: currentPatient.NGAY_SINH,
                ly_do_mien_giam: data.LY_DO_MIEN_GIAM,
                // "SO_PHIEU": "242401847",
                MA_CUOC_KHAM: currentPatient.MA_CUOC_KHAM
            },
            resDT: [],
            cac_phuong_thuc_tt: [],
            // nhom_dv: [],
            // nguoi_hien_thi: userProfile.CHU_KY || getFullName(userProfile),
            nhom: "THANH_TOAN",
            template: "phieuThuPhiDV"
        }
        //
        let nhom_dv = []
        dataSource.forEach(item => {
            const index = nhom_dv.findIndex(i => i.ID === item.PHONG_THUC_HIEN_ID)
            if (index >= 0) {
                nhom_dv[index].data.push(item)
            } else {
                nhom_dv.push({
                    ID: item.PHONG_THUC_HIEN_ID,
                    // PHONG_THUC_HIEN: PHONG.find(i => i.ID === item.PHONG_THUC_HIEN)?.TEN_KHOA_PHONG,
                    data: [item],
                })
            }
        })
        nhom_dv.forEach((dv, idx) => {
            let arr = []
            dv.data.forEach(item => {
                const index = arr.findIndex(i => i.TEN_LOAI_DICH_VU === item.TEN_LOAI_DICH_VU)
                if (index >= 0) {
                    arr[index].data.push(item)
                } else {
                    arr.push({
                        TEN_LOAI_DV: item.TEN_LOAI_DICH_VU,
                        data: [item],
                    })
                }
            })
            nhom_dv[idx].data = arr
        })
        //
        return { ...commonInfo, nhom_dv }
    }

    return {
        dsHinhThucThanhToan,
        dsSoHoaDon,
        formatDataSource,
        tongTienCanThanhToan,
        checkDuplicateMethod,
        handleSelectPaymentMethod,
        tongChiPhi,
        tongMienGiam,
    }

}

export default useDvChTT